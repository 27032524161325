import * as ReduxObservable from 'redux-observable'
import { map } from 'rxjs/operators'
import * as GA from '../../../../google-analytics'
import { GPT_IMAGE_GENERATE_ACTION } from '../constants'

const onModalAction: ReduxObservable.Epic = (action$, state$, { history, user }) => {
  return action$.ofType(GPT_IMAGE_GENERATE_ACTION)
    .pipe(
      map((action) => action.data),
      map((event: GA.Event4) => ({type: GA.SEND_EVENT_4, event})),
    )
}

export const epic = ReduxObservable.combineEpics(
  onModalAction,
)