import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { Loading } from 'components/loading'
import { format } from 'date-fns'
import * as React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import { DATEPICKER_DATE_FORMAT, DATETIME_FORMAT } from 'shared/constants'
import * as Modals from 'shared/modals'
import { TURN_OFF, TURN_ON } from './constants'

interface CalendarModalProps extends Modals.ModalComponentProps {
  update: (issued: any) => void
}

export class CalendarModalComponent extends Modals.GenericModalComponent<
  CalendarModalProps,
  Modals.ModalComponentState
> {
  public static key: string = 'calendarModal'
  constructor(props: CalendarModalProps) {
    super(props, CalendarModalComponent.key, 'calendarModal')
    this.state = {
      isSaving: false,
    }
  }

  protected renderChildren(data: any) {
    const sendDateTime =
      data && data.schedule ? data.schedule.start.toDate() : new Date()
    const displayScheduledContent = data && data.schedule && data.schedule.body
    ? data.schedule.body.updatedIssue.hasScheduledContent
    : null
    return (
      <div className="calendar-modal-body">
        {this.state.isSaving
        ? <Loading size="64" />
        : <div>
            <div className="send-time">
              <h4>Schedule send time</h4>
              <p>{format(sendDateTime, DATETIME_FORMAT)}</p>
            </div>
            {displayScheduledContent &&
            <div className="scheduled-content-preview">
              <h4>You have Scheduled Content for this newletter</h4>
              <p onClick={this.goToScheduledContent}>View Scheduled Content</p>
            </div>
            }
          </div>
        }
      </div>
    )
  }

  protected doSave(data: any) {
    const selectedIssue = data.schedule.body
      .updatedIssue
    this.props.update(selectedIssue)
    return Promise.resolve({})
  }

  protected getSaveText(data: any): string {
    return data && data.schedule && data.schedule.body.updatedIssue.is_active ? TURN_OFF : TURN_ON
  }

  private goToScheduledContent = () => {
    const { data } = this.props.data[CalendarModalComponent.key]
    const dateString = format(data.schedule.start.toDate(), DATEPICKER_DATE_FORMAT)
    const route = DashboardMenuOption.scheduledContent
    this.props.goTo(`${route}?startDate=${dateString}&endDate=${dateString}`)
  }

}

export const CalendarModal = connect(null, {
  push: Router.push,
})(CalendarModalComponent)
