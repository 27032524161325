import { GridCellProps } from '@progress/kendo-react-grid'
import React from 'react'
import { ToggleOff } from '../../../components/icons/toggleoff'
import { ToggleOn } from '../../../components/icons/toggleon'

interface HolidayCellState {
  isActive: boolean
}

export class HolidayCell extends React.Component<GridCellProps, HolidayCellState> {
  constructor(props: GridCellProps) {
    super(props)
    this.state = {
      isActive: this.props.dataItem.is_active,
    }
  }

  public render() {
    return (
      <td >
        <div onClick={(e) => this.toggle(e)} className="toggle-active">
          <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
            {this.state.isActive ? <ToggleOn/> : <ToggleOff/>}
          </button>
        </div>
      </td>
    )
  }

  private toggle = (e: any) => {
    const newIsActive: boolean = !this.state.isActive
    if (this.props.onChange) {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: e,
        field: 'is_active',
        value: newIsActive,
      })
    }
    this.setState({
      isActive: newIsActive,
    })
  }
}
