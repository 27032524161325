import isEmpty from 'lodash/isEmpty'
import { WeekInfo, WeekOfMonth } from './data'

export function createNewFullWeeksOfMonth(showFifthWeek: boolean = false): WeekInfo[] {
  const weeks = [
    {
      week: WeekOfMonth.FirstWeek,
      recommended: false,
      selected: false,
    },
    {
      week: WeekOfMonth.SecoundWeek,
      recommended: false,
      selected: false,
    },
    {
      week: WeekOfMonth.ThirdWeek,
      recommended: false,
      selected: false,
    },
    {
      week: WeekOfMonth.FourthWeek,
      recommended: false,
      selected: false,
    },
  ]

  // only show fifth week when it's selected, otherwise don't show in ui
  if (showFifthWeek) {
    weeks.push({
      week: WeekOfMonth.FifthWeek,
      recommended: false,
      selected: false,
    })
  }

  return weeks
}

const mapToDBWeekFormat = (item: WeekInfo): string => {
  if (item.selected) {
      return item.week.substring(0, 1).toUpperCase()
    }
}

export const createLocalWeekArrayFromDBString = (monthWeekString: string): WeekInfo[] => {
  const weeks: WeekInfo[] = createNewFullWeeksOfMonth(monthWeekString === '5')

  for (const monthWeekLetter of monthWeekString) {
    let monthWeekVal: WeekOfMonth
    switch (monthWeekLetter.toUpperCase()) {
      case '1': monthWeekVal = WeekOfMonth.FirstWeek; break;
      case '2': monthWeekVal =  WeekOfMonth.SecoundWeek; break;
      case '3': monthWeekVal = WeekOfMonth.ThirdWeek; break;
      case '4': monthWeekVal =  WeekOfMonth.FourthWeek; break;
      case '5': monthWeekVal =  WeekOfMonth.FifthWeek; break;
    }
    const theWeek = weeks.find((item: WeekInfo) => item.week === monthWeekVal)
    if (theWeek) {
      theWeek.selected = true
    }
  }
  return weeks
}

export const createDBWeeksFormatString = (weeks: WeekInfo[]): string => {
  const selectedWeeks = weeks.filter((w) => w.selected)
  return selectedWeeks.map((d: WeekInfo) => mapToDBWeekFormat(d)).join(',')
}

export const createMonthlyWeekData = (selectedWeeks: WeekInfo[]): WeekInfo[] => {
  const selectedWeek = selectedWeeks.find((w: WeekInfo) => w.selected)
  const showFifthWeek = selectedWeek ? selectedWeek.week === WeekOfMonth.FifthWeek : false
  const weeksData: WeekInfo[] = createNewFullWeeksOfMonth(showFifthWeek)
  if (!isEmpty(selectedWeeks)) {
    return weeksData.map((w: WeekInfo) => ({
      ...w,
      selected: selectedWeeks.filter((selected: WeekInfo) => selected.week === w.week)[0].selected,
    }))
  } else {
    return weeksData
  }
}
