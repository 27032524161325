import isEmpty from 'lodash/isEmpty'
import { DayInfo, DayOfWeek } from './data'

function createNewFullWeekOfDays(): DayInfo[] {
  return [
    {
      day: DayOfWeek.Sunday,
      recommended: false,
      selected: false,
    },
    {
      day: DayOfWeek.Monday,
      recommended: false,
      selected: false,
    },
    {
      day: DayOfWeek.Tuesday,
      recommended: false,
      selected: false,
    },
    {
      day: DayOfWeek.Wednesday,
      recommended: false,
      selected: false,
    },
    {
      day: DayOfWeek.Thursday,
      recommended: false,
      selected: false,
    },
    {
      day: DayOfWeek.Friday,
      recommended: false,
      selected: false,
    },
    {
      day: DayOfWeek.Saturday,
      recommended: false,
      selected: false,
    },
  ]
}

const mapToDBDayFormat = (item: DayInfo): string => {
  if (item.selected) {
    switch (item.day.toLowerCase().trim()) {
      case 'monday': case 'tuesday': case 'wednesday': case 'friday': case 'saturday':
        return item.day.substring(0, 1).toUpperCase()
      case 'thursday':
        return 'R'
      case 'sunday':
        return 'U'
    }
  }
  return ''
}

export const createLocalDayArrayFromDBString = (dayString: string): DayInfo[] => {

  const days: DayInfo[] = createNewFullWeekOfDays()

  for (const dayLetter of dayString) {
    let dayVal: DayOfWeek
    switch (dayLetter.toUpperCase()) {
      case 'M': dayVal = DayOfWeek.Monday; break;
      case 'T': dayVal = DayOfWeek.Tuesday; break;
      case 'W': dayVal = DayOfWeek.Wednesday; break;
      case 'F': dayVal = DayOfWeek.Friday; break;
      case 'S': dayVal = DayOfWeek.Saturday; break;
      case 'R': dayVal = DayOfWeek.Thursday; break;
      case 'U': dayVal = DayOfWeek.Sunday; break;
    }
    const theDay = days.find((item: DayInfo) => item.day === dayVal)
    if (theDay) {
      theDay.selected = true
    }
  }

  return days
}

export const createDBDayFormatString = (days: DayInfo[]): string => {
  return days.map((d: DayInfo) => mapToDBDayFormat(d)).join('')
}

export const createDayData = (selectedDays: DayInfo[]): DayInfo[] => {
  const daysData: DayInfo[] = createNewFullWeekOfDays()
  if (!isEmpty(selectedDays)) {
    return daysData.map((d: DayInfo) => ({
      ...d,
      selected: selectedDays.filter((selected: DayInfo) => selected.day === d.day)[0].selected,
    }))
  } else {
    return daysData
  }
}
