import React, {Component} from 'react'
import {DayInfo} from './data'
import './styles.css'

interface SingleDayProps {
  dayInfo: DayInfo,
  onChange: any,
}

interface SingleDayState {
  dayInfo: DayInfo,
}

export class SingleDay extends Component<SingleDayProps, SingleDayState> {
  constructor(props: SingleDayProps) {
    super(props)
    this.state = {dayInfo: props.dayInfo}
  }

  public componentDidUpdate(prevProps: SingleDayProps, prevState: SingleDayState, snapshot: any) {
    if (this.props.dayInfo.selected !== prevProps.dayInfo.selected) {
      this.setStateFromProps(false, this.props)
    }
  }

  public render() {
    let sClass: string = 'schedule-frequency-daily-item clickable-item'

    if (this.state.dayInfo.selected) {
      sClass += ' schedule-frequency-item-selected schedule-frequency-daily-item-selected '
    }

    return  <div className={sClass} onClick={(e) => this.handleClick(e)}>
                {this.state.dayInfo.selected && (<div className="schedule-item-checkbox" />)}
                <div className="schedule-item-pad-top">{this.state.dayInfo.day}</div>
                {this.state.dayInfo.recommended && (<div className="schedule-item-recommended">(Recommended)</div>)}
            </div>
  }

  private handleClick(e: any) {
    const newState: SingleDayState = {
      ...this.state,
      dayInfo: {
        ...this.state.dayInfo,
        selected: !this.state.dayInfo.selected,
      },
    }

    this.setState(newState, () => {
      if (this.props.onChange) {
        this.props.onChange(newState.dayInfo)
      }
    })
  }

  private setStateFromProps(bDirect: boolean, props: SingleDayProps, onComplete: any = () => ({})) {
    const newState = {...props}
    if (bDirect) {
      this.state = newState
    } else {
      this.setState(newState, onComplete)
    }
  }
}
