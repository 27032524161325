import { Grid, GridCell, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid'
import { RasaContext } from 'context'
import { addMonths } from 'date-fns'
import isNil from 'lodash/isNil'
import React, {Component} from 'react'
import { Row } from 'reactstrap'
import { getCommunityUpcomingIssues } from 'shared/data-layer/issues'
import * as GenericRedux from '../../../generic/genericRedux'
import { HolidayCell } from '../kendocells/holidayCell'

import './_styles.scss'

const titleOptions: any = {
  month: 'long',
  day: 'numeric',
}

const monthOptions: any = {
  month: 'long',
  year: 'numeric',
}

const descriptionOptions: any = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

class DateCell extends GridCell {
  public render() {
    const d = this.props.dataItem.issue_date
    return <td className="mobile-date-cell">
      <span className="mobile-title">{d.toLocaleDateString('en-US', titleOptions)}</span>
      <br/>
      <span className="mobile-description">{d.toLocaleDateString('en-US', descriptionOptions)}</span>
    </td>
  }
}

interface UpcomingIssue {
  date: string,
  holiday_id: number,
  is_active: boolean,
  id: number,
  issue_date: Date,
}

const normalizeData = (issues: UpcomingIssue[]): UpcomingIssue[] => {
  return issues.map((i: UpcomingIssue) => ({
    ...i,
    id: i.holiday_id,
    issue_date: new Date(i.date),
  }))
}

interface MonthAndIssues {
  date: Date,
  issues: UpcomingIssue[],
}

const nextTwelveMonths = (): Date[] => {
  const today = new Date()
  return [...Array(12).keys()].map((i: number) => addMonths(today, i))
}

type ScheduleUpcomingIssuesProps = GenericRedux.DatasetComponentProps<UpcomingIssue>

class ScheduleUpcomingIssuesComponent extends Component<ScheduleUpcomingIssuesProps, object> {
  public static contextType = RasaContext;
  private communityId: string = null
  constructor(props: ScheduleUpcomingIssuesProps) {
    super(props)
  }

  public componentDidMount() {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.communityId = activeCommunity.communityId
      return getCommunityUpcomingIssues(activeCommunity.communityId)
        .then((upcomingIssues) => this.props.datasetLoaded(upcomingIssues))
    })
  }

  public render() {
    return <div className="mobile-schedule-upcoming-issues-wrap">
      <div className="mobile-schedule-header-section">
        <div className="mobile-section-header-text">Upcoming Schedule</div>
        <div className="mobile-detail-text">
        Dates of when your newsletter is scheduled to send. Turn off a date by making it inactive.
        </div>
      </div>
      {this.organizeIssuesByMonth()
           .filter((m: MonthAndIssues) => m.issues.length > 0)
           .map((m: MonthAndIssues, i: number) =>
        <Row key={`key-${i}`}>
          <this.ScheduleGrid issues={m.issues} date={m.date}/>
        </Row>)}
    </div>
  }

  private ToggleHolidayCell = (props: GridCellProps) => {
    return <HolidayCell {...props} onChange={this.toggleHoliday}/>
  }

  private toggleHoliday = (e: any) => {
    return this.context.entityMetadata.getEntityObject('community_holiday').then((entity) => {
      entity.setRecordId(this.communityId, e.dataItem.id);
      entity.data.is_active = !e.value
      if (isNil(e.dataItem.id)) {
        entity.data.holiday = e.dataItem.date
        entity.data.name = e.dataItem.date
        entity.data.community_identifier = this.communityId
      }
      return entity.save()
    })
  }

  private organizeIssuesByMonth = (): MonthAndIssues[] => {
    const issues = normalizeData(this.props.data)
    return nextTwelveMonths().map((date: Date) => ({
      date,
      issues: issues.filter((i: UpcomingIssue) => i.issue_date.getMonth() === date.getMonth()),
    }))
  }

  private ScheduleGrid = ({date, issues}: MonthAndIssues) => <div className="mobile-schedule-box">
    <div className="mobile-month-label">
      <span className="mobile-month-label-header">Month of</span>
      <br/>
      <span className="month">{date.toLocaleDateString('en-US', monthOptions)}</span>
    </div>
    <Grid data={issues} className="mobile-issues-grid">
      <Column cell={this.ToggleHolidayCell} title="Active" width={100}/>
      <Column field="date" cell={DateCell} title="Date"/>
    </Grid>
  </div>

}

export const MobileScheduleUpcomingIssues = GenericRedux.registerNewDatasetComponent<UpcomingIssue>(
  ScheduleUpcomingIssuesComponent, 'upcoming_issues')
