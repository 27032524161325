import { DropdownOption } from 'components/dropdown/component'
import { RasaContext } from 'context'
import * as Modals from 'shared/modals'
import { ScheduledContentTypes } from 'shared_server_client/constants'
import { resetToStartOfDayZoned } from 'shared_server_client/dates'
import {
  ClearScheduledContentDataState,
  CONTENT_ALREADY_EXISTS,
  CONTENT_ALREADY_EXISTS_MESSAGE,
  CONTENT_IMAGE_ALREADY_EXISTS_MESSAGE,
  CONTENT_TEXT_ALREADY_EXISTS_MESSAGE,
  FEATURE_TYPE,
  InitialScheduledContentModalState
} from '../constants'
import { TemplateModule, TemplateModuleType } from "../../../../shared_server_client/types/email_layout";
import { BaseClientEntity } from "../../../../generic/baseClientEntity";

export interface EmailLayout  {
  id: number
  email_template: string
  banner_alt_text: string
  template_modules: TemplateModule[]
}

export interface ScheduleContentModalProps extends Modals.ModalComponentProps {
  emailLayout?: EmailLayout
  forceReload: () => any,
  locationOptions?: any[]
  featureType?: FEATURE_TYPE
}

export interface Location {
  key: string
  value: string
}

export interface SCModalState extends Modals.ModalComponentState {
  activeCommunity?: any,
  endDate: Date
  errorMessage: string
  hasSectionAccess?: boolean
  imageDisplayType?: string
  imageName: string
  imageUrl: string
  isEditingImage: boolean
  isDirty: boolean
  isLoading: boolean
  isUploading: boolean
  isValid?: boolean
  location: Location
  maxSponsoredPosts?: number
  selectedLocation: DropdownOption
  locationOptions?: DropdownOption[]
  modalErrorMessage: string
  scheduleErrorMessage: string
  selectedSection: DropdownOption
  sections?: DropdownOption[]
  showImageUploadButtons?: boolean
  showSaveConfirmationModal?: boolean
  sourceUrl: string
  startDate: Date
  typeId: string
  useUrl: boolean
  validUrl: boolean
  hasImageGalleryAccess?: boolean
  isSuperUser?: boolean
}

export class ScheduleContentModalBaseComponent extends Modals.GenericModalComponent<
  ScheduleContentModalProps,
  SCModalState
> {
  public static contextType = RasaContext

  constructor(props: ScheduleContentModalProps, key: string) {
    super(props, key)
    this.state = InitialScheduledContentModalState
  }

  protected getSaveText(data: any): string {
    return 'Save & Schedule'
  }

  protected normalizeItem = (scheduledContentItem: any) => {
    return scheduledContentItem
  }

  protected changeLocation = (e) => {
    this.updateProperty('location', e.selected)
  }

  protected getTitleText(data: any): string {
    return null
  }

  protected saveDisabled(data: any) {
    return false
  }

  protected xButtonClick(data: any) {
    // NOT IMPLEMENTED
  }

  protected createOrUpdate(payload: any): Promise<any> {
    return Promise.resolve(null)
  }

  protected doSave(data: any) {
    this.setState({isSaving: true})
    return this.createOrUpdate(data)
    .then(() => {
      const currentIsSuperUser = this.state.isSuperUser
      this.setState({
        ...ClearScheduledContentDataState,
        isSuperUser: currentIsSuperUser,
      })

      this.props.forceReload()
      this.props.closeModal(`${data.content_type}Modal`)
      return Promise.resolve({})
    })
    .catch((error) => {
      const {timezone} = data
      this.setState({
        modalErrorMessage: error.response.message === CONTENT_ALREADY_EXISTS
          ? (this.getContentAlreadyExistsMessage(data.content_type)
            + ' Conflicts: ' +
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          this.formatConflicts(error.response.error.conflicts, timezone))
          : error.response.message,
        isUploading: false,
        isSaving: false,
      })
      return Promise.reject({})
    }).finally(() => this.setState({isSaving: false}))
  }

  protected formatConflicts(conflicts: [], timezone: string) {
    return conflicts.map((c: any) => {
      const startDate = resetToStartOfDayZoned(c.start_date, timezone)
      const endDate = resetToStartOfDayZoned(c.end_date, timezone)
      return startDate + '/' + endDate
    })
  }

  protected renderChildren(data: any) {
    // NOT IMPLEMENTED
  }

  protected updateProperty(property, value) {
    // NOT IMPLEMENTED
  }

  protected updateSourceUrl = (e) => {
    // NOT IMPLEMENTED
  }

  protected validateUrl = (url: string) => {
    // NOT IMPLEMENTED
  }

  protected handleKeyPress = (target: any) => {
    if (target.charCode === 13 && this.state.validUrl) {
      this.validateFetch(this.state.sourceUrl)
    }
  }

  protected validateFetch = (inputUrl: string) => {
    // NOT IMPLEMENTED
  }

  protected fetch = (inputUrl: string, urlType: string): Promise<any> => {
    return Promise.resolve(null)
  }

  protected enableScheduleContent = (selectedLocation: string, communityId: string) => {
    const found = this.findModule(selectedLocation)
    if(found === undefined){
      return
    }
    this.setState({
      isSaving: true
    })
    this.context.entityMetadata.getEntityObject('email_layout', communityId, this.props.emailLayout.id)
      .then((emailLayoutEntityObject: BaseClientEntity) => {
        const index = this.props.emailLayout.template_modules.indexOf(found)
        this.props.emailLayout.template_modules[index].moduleConfig.isScheduled = true
        emailLayoutEntityObject.data.template_modules = this.props.emailLayout.template_modules
        this.setState({
          isSaving: false,
          'scheduleErrorMessage': ''
        })
        return emailLayoutEntityObject.save()
      })
      .catch()
  }

  protected isScheduleContentEnabled = (selectedLocation: string): boolean => {
    const found = this.findModule(selectedLocation)
    return found && found.moduleConfig && found.moduleConfig.isScheduled
  }

  protected findModule = (selectedLocation: string): TemplateModule | undefined => {
    const location = selectedLocation.split(' ')
    let sequence = Number(location.length > 1 ? location[1] : 1)
    let type = TemplateModuleType.image

    if (location[0] === 'Banner') {
      type = TemplateModuleType.image
    } else if (location[0] === 'Square') {
      sequence = Math.ceil(sequence / 2)
      type = TemplateModuleType.twoColumnImage
    } else if (location[0] === 'Text') {
      type = TemplateModuleType.leadText
    }

    return this.props.emailLayout.template_modules
      .find(x => x.type === type && x.sequence === sequence)
  }

  private getContentAlreadyExistsMessage(contentType: ScheduledContentTypes) {
    switch (contentType) {
      case ScheduledContentTypes.image:
        return CONTENT_IMAGE_ALREADY_EXISTS_MESSAGE
      case ScheduledContentTypes.text:
        return CONTENT_TEXT_ALREADY_EXISTS_MESSAGE
      default:
        return CONTENT_ALREADY_EXISTS_MESSAGE
    }
  }

}
