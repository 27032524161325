import { BaseClientEntity } from 'generic/baseClientEntity'
import { SCHEDULED_ARTICLES_BOOST_LEVEL, ScheduledContentTypes } from 'shared_server_client/constants'
import { endOfDay, startOfDay } from 'shared_server_client/dates'
import { InitialScheduledContentModalState } from '../constants'
import { AddArticleModalBaseComponent } from './addArticleBaseComponent'
import { ScheduleContentModalProps } from './base'

export class ScheduledArticleBaseComponent extends AddArticleModalBaseComponent {
  constructor(props: ScheduleContentModalProps, key: string) {
    super(props, key)
    this.state = InitialScheduledContentModalState
  }

  protected createOrUpdate(payload: any): Promise<any> {
    return this.context.entityMetadata
      .getEntityObject('scheduledContent', payload.communityId, null)
      .then((scheduledContentEntityObject: BaseClientEntity) => {
        const {description, endDate, startDate, typeId} = payload
        scheduledContentEntityObject.setRecordId(payload.communityId, typeId && !payload.isCloneItem ? typeId : null)
        scheduledContentEntityObject.data.contentType = ScheduledContentTypes.article
        scheduledContentEntityObject.data.endDate = endOfDay(endDate, payload.timezone)
        scheduledContentEntityObject.data.startDate = startOfDay(startDate, payload.timezone)
        scheduledContentEntityObject.data.community_partner_code = payload.community_partner_code
        scheduledContentEntityObject.data.url = payload.url
        scheduledContentEntityObject.data.override_url = payload.override_url
        scheduledContentEntityObject.data.description = description
        scheduledContentEntityObject.data.title = payload.title
        scheduledContentEntityObject.data.image_alt_text = payload.image_alt_text
        scheduledContentEntityObject.data.site_name = payload.publisher
        scheduledContentEntityObject.data.rank = payload.rank || 1
        scheduledContentEntityObject.data.featureType = payload.featureType || 'Lead'
        scheduledContentEntityObject.data.post_subscription_id = payload.post_subscription_id
        scheduledContentEntityObject.data.boost_level = SCHEDULED_ARTICLES_BOOST_LEVEL
        if (payload.hostedImage) {
          scheduledContentEntityObject.data.hosted_image_url = payload.hostedImage.url
          scheduledContentEntityObject.data.image_height = payload.hostedImage.height
          scheduledContentEntityObject.data.image_width = payload.hostedImage.width
          scheduledContentEntityObject.data.image_url = payload.hostedImage.url
        } else if (payload.image) {
          scheduledContentEntityObject.data.image_url = payload.image
        }
        scheduledContentEntityObject.data.custom_tags = payload.customTags
        scheduledContentEntityObject.data.content_category =  payload.contentCategory
        scheduledContentEntityObject.data.content_type =  'Lead'
        //only need the community identifier for Create, not Update
        if (!payload.typeId) {
          scheduledContentEntityObject.data.communityIdentifier =  payload.communityId
        }
        if (payload.isCloneItem) {
          scheduledContentEntityObject.data.is_clone = 1
        }
        return scheduledContentEntityObject.save()
      })
  }
}
