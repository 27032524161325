import React, {Component} from 'react'
import { WeekInfo} from './data'
import './styles.css'

interface WeeklyDayProps {
  weekInfo: WeekInfo,
  onChange: any,
}

interface WeeklyDayState {
  weekInfo: WeekInfo,
}

export class WeeklyDay extends Component<WeeklyDayProps, WeeklyDayState> {
  constructor(props: WeeklyDayProps) {
    super(props)
    this.state = {weekInfo: props.weekInfo}
  }

  public componentDidUpdate(prevProps: WeeklyDayProps, prevState: WeeklyDayState, snapshot: any) {
    if (this.props.weekInfo.selected !== prevProps.weekInfo.selected) {
      this.setStateFromProps(false, this.props)
    }
  }

  public render() {
    let sClass: string = 'schedule-frequency-daily-item clickable-item'

    if (this.state.weekInfo.selected) {
      sClass += ' schedule-frequency-item-selected schedule-frequency-daily-item-selected '
    }

    return  <div className={sClass} onClick={(e) => this.handleClick(e)}>
                {this.state.weekInfo.selected && (<div className="schedule-item-checkbox" />)}
                <div className="schedule-item-pad-top">{this.state.weekInfo.week}</div>
                {this.state.weekInfo.recommended && (<div className="schedule-item-recommended">(Recommended)</div>)}
            </div>
  }

  private handleClick(e: any) {
    const newState: WeeklyDayState = {
      ...this.state,
      weekInfo: {
        ...this.state.weekInfo,
        selected: !this.state.weekInfo.selected,
      },
    }

    this.setState(newState, () => {
      if (this.props.onChange) {
        this.props.onChange(newState.weekInfo)
      }
    })
  }

  private setStateFromProps(bDirect: boolean, props: WeeklyDayProps, onComplete: any = () => ({})) {
    const newState = {...props}
    if (bDirect) {
      this.state = newState
    } else {
      this.setState(newState, onComplete)
    }
  }
}
