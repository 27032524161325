import React, {Component} from 'react'
import './styles.css'

import {DayInfo} from './data'
import {SingleDay} from './singleDay'
import * as Weekdays from './weekdays'

interface MultiDaySelectorProps {
  onChange: any,
  selectedDays: DayInfo[],
}

interface MultiDaySelectorState {
  dayData: DayInfo[],
}
export class MultiDaySelector extends Component<MultiDaySelectorProps, MultiDaySelectorState> {
    constructor(props: MultiDaySelectorProps) {
      super(props)
      this.setStateFromProps(true, props)
    }

    public render() {
      return <div className="">
        <div className="clearfix schedule-new-group schedule-header-text">Choose your days</div>
        <div className="schedule-detail-text">Select one or more days of the week to send</div>
        <div className="schedule-frequency-daily-group">
          {this.createDays()}
        </div>
      </div>
  }

  public componentDidUpdate(prevProps: MultiDaySelectorProps, prevState: MultiDaySelectorState) {
    if (!this.compareDays(prevProps.selectedDays, this.props.selectedDays)) {
      // prev props and current props are different, means we got an update from outside
      // update our state
      this.setStateFromProps(false, this.props)
    }
  }

  private compareDays(days1: DayInfo[], days2: DayInfo[]) {
    if (days1.length !== days2.length) {
      return false
    } else {
      for (const day1Item of days1) {
        // find corresponding item in days2
        const day2Item = days2.find((d2) => d2.day === day1Item.day)
        if (!day2Item || day2Item.selected !== day1Item.selected) {
          return false
        }
      }
      return true // if we get here nothing was different
    }
  }

  private handleDayChange(selectedDay: DayInfo) {
    const newDayData: DayInfo[] = this.state.dayData.map((d: DayInfo) => {
      return d.day === selectedDay.day ? {...d, selected: selectedDay.selected} : d
    })

      // update our internal state
    this.setState((state) => {
      return {
        ...state,
        dayData: newDayData,
      }
    })

    if (this.props.onChange) {
      this.props.onChange(newDayData)
    }
  }

  private createDay(day: DayInfo) {
    return  <SingleDay key={day.day} dayInfo={day} onChange={(e: DayInfo) => this.handleDayChange(e)}/>
  }

  private createDays() {
    return this.state.dayData.map((item) => this.createDay(item))
  }

  private setStateFromProps(bDirect: boolean, props: MultiDaySelectorProps, onComplete: any = () => ({})) {
    let newState;
    if (props) {
      newState = { dayData: Weekdays.createDayData(props.selectedDays) }
    } else {
      newState = { dayData: Weekdays.createDayData(null) }
    }
    if (bDirect) {
      this.state = newState
    } else {
      this.setState(newState, onComplete)
    }
  }
}
