import * as Sentry from '@sentry/browser'
import Calendar from '@toast-ui/react-calendar'
import { HeaderComponent } from 'components/header/component'
import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { addDays, addMonths, format } from 'date-fns'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { isTruthy } from 'generic/utility'
import isNil from 'lodash/isNil'
import React, { Component, ComponentType } from 'react'
import { getCommunityUpcomingIssues } from 'shared/data-layer/issues'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { DEFAULT_TIMEZONE, ScheduleFrequency } from 'shared_server_client/constants'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { HolidaySchedule, UpcomingIssue } from 'shared_server_client/types/schedule'
import 'tui-calendar/dist/tui-calendar.css'
import 'tui-time-picker/dist/tui-time-picker.css'
import './_styles.scss'
import { CALENDAR_HEIGHT, SCHEDULE, UPCOMING_NEWSLETTERS } from './constants'
import { CalendarModal } from './modals'
import { getMonthYear, mapSchedules, normalizeHolidays, normalizeIssues } from './utils'
import { ConnectedComponentClass } from 'react-redux'
import {Fields} from "../../../shared/modals";

export const COUNTRY_CONFIGURATION_KEY = 'show_holidays_in_calendar'

interface RCProps {
  hasScheduledContentAccess: boolean,
  issues: UpcomingIssue[],
  countryHolidays: HolidaySchedule[],
  update: (issue) => void
}
interface RasaCalendarState {
  saveText: string,
  viewDate: Date
}

type RasaCalendarProps = GenericRedux.AllComponentPropsWithModal<any> & RCProps

class RasaCalendarComponent extends React.Component<RasaCalendarProps, RasaCalendarState> {
  private calendarRef: React.RefObject<Calendar>
  constructor(props) {
    super(props)
    this.calendarRef = React.createRef()
    this.state = {
      saveText: '',
      viewDate: new Date(),
    }
  }

  public render() {
    return (
      <div>
        <div className="date-and-buttons-container">
          <div className="date-container">
            {getMonthYear(this.state.viewDate)}
          </div>
          <div className="buttons-container">
            <div className="today" onClick={this.handleClickTodayButton}>
              Today
            </div>
            <div className="prev" onClick={this.handleClickPreviousButton}>
              {'<'}
            </div>
            <div className="next" onClick={this.handleClickNextButton}>
              {'>'}
            </div>
          </div>
        </div>
        <Calendar
          ref={this.calendarRef}
          height={CALENDAR_HEIGHT}
          view={'month'}
          taskView={false}
          scheduleView={true}
          month={{
            isAlways6Week: false,
            startDayOfWeek: 0,
          }}
          isReadOnly={false}
          useCreationPopup={false}
          useDetailPopup={false}
          onClickSchedule={this.handleClickSchedule}
          schedules={mapSchedules(this.props.issues, this.props.countryHolidays,
            this.props.hasScheduledContentAccess)}
          usageStatistics={false}
          theme={{
            'month.day.fontSize': '16px',
          }}

          template={{
            monthDayname(model) {
              return String(model.label).toLocaleUpperCase()
            },
            milestone(model) {
              return `<span style="color:#fff;background-color: ${model.bgColor};">${model.title}</span>`;
            },
            allday(schedule) {
              return `<span className='calendar-event-holiday'>${schedule.title}</span>`
            },
          }}
        />
        <CalendarModal
          data={this.props.modals}
          closeModal={this.props.closeModal}
          goTo={this.goTo}
          saveModal={this.props.saveModal}
          title="Update Newsletter"
          update={this.props.update}
        />
      </div>
    )
  }

  private handleClickTodayButton = () => {
    this.setState({ viewDate: new Date() })
    const calendarInstance = this.calendarRef.current.getInstance()
    calendarInstance.today()
  }

  private handleClickNextButton = () => {
    this.setState({ viewDate: addMonths(this.state.viewDate, 1) })
    const calendarInstance = this.calendarRef.current.getInstance()
    calendarInstance.next()
  }

  private handleClickPreviousButton = () => {
    const newViewDate = addMonths(this.state.viewDate, -1)
    if (newViewDate >= addDays(new Date(), -1)) {
      this.setState({ viewDate: newViewDate })
      const calendarInstance = this.calendarRef.current.getInstance()
      calendarInstance.prev()
    }
  }

  private handleClickSchedule = (e) => {
    if (e.schedule.body.updatedIssue && e.schedule.body.updatedIssue.schedule.is_active) {
      this.props.openModal(CalendarModal.key, e)
    }
  }

  private goTo = (location) => {
    this.props.closeModal(CalendarModal.key)
    this.props.push(location)
    }

}

export const RasaCalendar: ConnectedComponentClass<ComponentType<RasaCalendarComponent>, Fields> = GenericRedux.registerNewComponentWithModals<RasaCalendarState>(
  RasaCalendarComponent,
  'rasa_calendar',
  [CalendarModal.key],
  {
    saveText: '',
    viewDate: new Date(),
  },
)

interface CalendarComponentState {
  activeSchedule: boolean
  hasScheduledContentAccess: boolean
  isLoading: boolean
  isManualSchedule: boolean
  issues: UpcomingIssue[]
  communityIdentifier: string
  communityId: string
  isCountryHolidayDisplay: boolean
  countryHolidays: HolidaySchedule[]
}
export class CalendarComponent extends Component<any, CalendarComponentState> {
  public static contextType = RasaContext
  private communityId: string = null
  private sharedStore: SharedStore
  private timezone: string = null

  constructor(props) {
    super(props)
    this.state = {
      activeSchedule: true,
      hasScheduledContentAccess: false,
      isLoading: true,
      isManualSchedule: false,
      issues: [],
      communityIdentifier: null,
      communityId: null,
      isCountryHolidayDisplay: false,
      countryHolidays: [],
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ]).then(([activeCommunity, person]) => {
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.communityId = activeCommunity.communityId
      this.timezone = activeCommunity.data && activeCommunity.data.company_time_zone
          ? activeCommunity.data.company_time_zone
          : DEFAULT_TIMEZONE
      return  Promise.all([getCommunityUpcomingIssues(activeCommunity.communityId),
        new Dataset().loadCommunityDataset('countryHolidays', activeCommunity.communityId),
        this.loadData(false)])
      .then(([upcomingIssues, holidays]: [any, any, any]) => {
        this.setState({
          hasScheduledContentAccess: avlFeatures.indexOf(BillingPlanDetailCode.SCHEDULED_CONTENT) > -1,
          issues: normalizeIssues(upcomingIssues, this.timezone),
          isLoading: false,
          activeSchedule: activeCommunity.data.schedule_is_active,
          isManualSchedule: activeCommunity.data.schedule_frequency === ScheduleFrequency.manually,
          communityIdentifier: activeCommunity.communityId,
          communityId: activeCommunity.data.community_id,
          countryHolidays: holidays[0] ? normalizeHolidays(holidays[0], this.timezone) : [],
        })
      })
    })
  }

  public render = () => {
    let description = []
    if (this.state.isManualSchedule) {
      description = [
        'There is no upcoming newsletters',
    ]
    } else {
      description = [
        'A calendar view of when your newsletter is scheduled to send.',
        'Turn on and off specific dates by clicking on them.',
      ]
    }
    return (
      this.state.isLoading ? <Loading size="64"/> :
      <div className="content-calendar">
        <HeaderComponent
          description={description}
          title={SCHEDULE}
          subTitle={UPCOMING_NEWSLETTERS}
        />
        <div>
          {!this.state.isManualSchedule &&
          <div className="holiday-toggle">
            <div className="clickable-item" onClick={() => this.onToggleChange()}>
              <label>US Holidays</label>
              {this.state.isCountryHolidayDisplay
                ? <ToggleOn/>
                : <ToggleOff/>}
            </div>
          </div>}
          {this.state.activeSchedule ?
            this.state.isManualSchedule ?
            <span>You have set your schedule to manual. Once your newsletter is sent,
                  you will be able to access the schedule page again.</span>
            : <RasaCalendar hasScheduledContentAccess={this.state.hasScheduledContentAccess}
                issues={this.state.issues} update={this.updateIssues}
                countryHolidays={this.state.isCountryHolidayDisplay ? this.state.countryHolidays : []}/>
            : <span>Your schedule is currently paused. <a href="/schedule">Click here</a> to turn it back on</span>
          }
        </div>
      </div>
    )
  }

  private onToggleChange = () => {
    return this.context.entityMetadata.getEntityObject('community_configuration')
    .then((entity) => {
      entity.setRecordId(this.state.communityIdentifier, null)
      entity.data.community_id = this.state.communityId
      entity.data.key = COUNTRY_CONFIGURATION_KEY
      entity.data.value = !this.state.isCountryHolidayDisplay
      entity.data.is_active = true

      return entity.save().then(() => {
        this.setState({isCountryHolidayDisplay: !this.state.isCountryHolidayDisplay})
        this.loadData(true)
      })
    })
  }

  private loadData = (forceRefresh: boolean) => {
    const params = [
      {param: 'key', value: COUNTRY_CONFIGURATION_KEY},
      {param: 'communityId', value: this.communityId},
      {param: 'forceRefresh', value: forceRefresh},
    ]
    return new Dataset().loadCommunityDataset('communityConfiguration', this.communityId, params)
      .then((configuration) => {
        if (configuration.length > 0 && configuration[0].length > 0) {
          this.setState({
            isCountryHolidayDisplay: isTruthy(configuration[0][0].value),
          })
        }
      })
  }

  private updateIssues = (issue: UpcomingIssue) => {
    const updatedIssues = this.state.issues.map((i: UpcomingIssue) => {
      return {
        ...i,
        is_active: issue.date === i.date ? !i.is_active : i.is_active,
      }
    })

    return this.context.entityMetadata.getEntityObject('community_holiday')
      .then((entity) => {
        entity.setRecordId(this.communityId, issue.holiday_id)
        entity.data.is_active = issue.is_active
        if (isNil(issue.holiday_id)) {
          // note that issue.issue_date is a Date object which value is in the newsletter timezone
          entity.data.holiday = format(issue.issue_date, 'yyyy-MM-dd')
          entity.data.name = issue.date
          entity.data.community_identifier = this.communityId
        }
        return entity.save()
      })
      .then(() => this.setState({ issues: updatedIssues }))
      .catch((err) =>  Sentry.captureException(err))
  }
}
