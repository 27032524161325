import { GPT_IMAGE_GENERATE_ACTION } from './constants'

export const saveGptImageEvent = (eventName: string,key: string, source: string) => {
  return {
    type: GPT_IMAGE_GENERATE_ACTION,
    data: {
      name: eventName,
      payload: {
        key,
        source
      },
    },
  }
}