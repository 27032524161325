import { DatePicker } from '@progress/kendo-react-dateinputs'
import { SelectColor } from 'components/colors/component'
import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { DropdownComponent } from 'components/dropdown/component'
import {
  BORDER_FONT_SIZES,
  FONT_SIZES,
  FONT_STYLES,
  FONT_WEIGHTS,
} from 'components/email-layout/constants'
import { Loading, SIZES } from 'components/loading'
import { WysiwygEditor } from 'components/tinymce-editor/component'
import { RasaContext } from 'context'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { isFalsey } from 'generic/utility'
import * as React from 'react'
import { Input } from 'reactstrap'
import { DATEPICKER_DATE_FORMAT, MYSQL_MEDIUM_TEXT_LENGTH } from 'shared/constants'
import { DEFAULT_COMMUNITY_PARTNER_CODES, leadTextAttributes, leadTextDefaults, ScheduledContentTypes } from 'shared_server_client/constants'
import { endOfDay,  startOfDay } from 'shared_server_client/dates'
import { isCustomTemplate } from 'shared_server_client/utils'
import {
  ClearScheduledContentDataState,
  InitialScheduledContentModalState,
  SCHEDULED_CONTENT_NOT_ACTIVE_MESSAGE
} from '../constants'
import { ScheduleContentModalBaseComponent, ScheduleContentModalProps } from './base'

export class ScheduledTextModalComponent extends ScheduleContentModalBaseComponent {
  public static key: string = 'scheduledTextModal'
  public static contextType = RasaContext

  constructor(props: ScheduleContentModalProps) {
    super(props, ScheduledTextModalComponent.key)
    this.state = InitialScheduledContentModalState
  }

  public renderChildren(data) {
    const {location} = data
    return (this.state.isSaving || !this.props.locationOptions)
    ? <Loading size={SIZES.LARGE}/>
    : <div className="scheduled-text-modal-body">
              <div className="body-container">
                <div className="left-side-container">
                  <div className="first-row-container">
                    <h5>Location</h5>
                    <DropdownComponent
                      data={this.props.locationOptions}
                      selected={location ? location.key : ''}
                      onChange={this.changeLocation}/>
                  </div>
                  <div className="second-row-container">
                    <h5>Border</h5>
                    <div className="second-row-inner-container">
                    <Input type="select"
                        className="static-dropdown margin-right-10"
                        value={this.getStaticTextStylesAttribute(
                          data, leadTextAttributes.borderWidth, leadTextDefaults.borderWidth)}
                        onChange={(e) => {
                          e.persist()
                          this.updateProperty('styles',
                          this.updateStaticTextStylesAttribute(data, leadTextAttributes.borderWidth, e.target.value))}}>
                      {BORDER_FONT_SIZES.map((s: string) => <option key={s} value ={s}>{s}</option>)}
                    </Input>
                    <SelectColor className={null}
                      color={this.getStaticTextStylesAttribute(
                      data, leadTextAttributes.borderColor, leadTextDefaults.borderColor)}
                      selectColor={(c: string) =>
                          this.updateProperty('styles',
                          this.updateStaticTextStylesAttribute(data, leadTextAttributes.borderColor, c))}/>
                    </div>
                  </div>
                  <div className="third-row-container">
                    <h5>Default Font Styles</h5>
                    <div className="third-row-inner-container">
                      <Input type="select"
                          className="static-dropdown margin-right-10"
                          value={this.getStaticTextStylesAttribute(
                          data, leadTextAttributes.fontSize, leadTextDefaults.fontSize)}
                          onChange={(e) => {
                            e.persist()
                            this.updateProperty('styles',
                            this.updateStaticTextStylesAttribute(data, leadTextAttributes.fontSize, e.target.value))}}>
                        {FONT_SIZES.map((s: string) => <option key={s} value ={s}>{s}</option>)}
                      </Input>
                      <SelectColor className={null}
                        color={this.getStaticTextStylesAttribute(
                        data, leadTextAttributes.color, leadTextDefaults.color)}
                        selectColor={(c: string) =>
                        this.updateProperty('styles',
                        this.updateStaticTextStylesAttribute(data, leadTextAttributes.color, c))}/>
                    </div>
                  </div>
                  <div className="fourth-row-container">
                    <Input type="select"
                      className="static-dropdown margin-right-10"
                      value={this.getStaticTextStylesAttribute(
                      data, leadTextAttributes.fontWeight, leadTextDefaults.fontWeight)}
                      onChange={(e) => {
                        e.persist()
                        this.updateProperty('styles',
                        this.updateStaticTextStylesAttribute(data, leadTextAttributes.fontWeight, e.target.value))}}>
                    {FONT_WEIGHTS.map((s: string) => <option key={s} value ={s}>{s}</option>)}
                    </Input>
                    <Input type="select"
                      className="static-dropdown"
                      value={this.getStaticTextStylesAttribute(
                      data, leadTextAttributes.fontStyle, leadTextDefaults.fontStyle)}
                      onChange={(e) => {
                        e.persist()
                        this.updateProperty('styles',
                        this.updateStaticTextStylesAttribute(data, leadTextAttributes.fontStyle, e.target.value))}}>
                      {FONT_STYLES.map((s: string) => <option key={s} value ={s}>{s}</option>)}
                    </Input>
                    </div>
                  <div className="fifth-row-container">
                    <div className="start-date-container">
                      <h5>Start Date</h5>
                      <DatePicker
                        className="modal-schedule-date-picker"
                        format={DATEPICKER_DATE_FORMAT}
                        value={data.startDate ? data.startDate : null}
                        onChange={(e) => this.updateProperty('startDate', e.target.value)}
                      />
                    </div>
                    <div className="end-date-container">
                      <h5>End Date</h5>
                      <DatePicker
                        className="modal-schedule-date-picker"
                        format={DATEPICKER_DATE_FORMAT}
                        value={data.endDate ? data.endDate : null}
                        onChange={(e) => this.updateProperty('endDate', e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="sixth-row-container">
                    <div className="segment-code-container">
                      <LoadSegmentCodes
                        label="Segment"
                        segmentCode={data.community_partner_code}
                        setSegmentData={this.setSegmentData}
                        setSegmentCode={this.setSegmentCode} />
                    </div>
                  </div>
                  {this.state.modalErrorMessage &&
                  <div className="error-container">
                    {this.state.modalErrorMessage}
                  </div>}
                  {
                    this.state.scheduleErrorMessage &&
                    <div className="error-container">
                      {this.state.scheduleErrorMessage}
                      <a onClick={() => this.enableScheduleContent(this.getSelectedLocation(), this.props.data.scheduledTextModal.data.communityId)} href="#">
                        Click here to toggle it on.
                      </a>
                    </div>
                  }
                </div>
                <div className="right-side-container">
                  <h5>Text / HTML</h5>
                  <div className="section">
                    <div className="description">
                    Enter your copy or html
                    </div>
                    <WysiwygEditor
                      showCharacterCount={true}
                      maxCharacterCount={MYSQL_MEDIUM_TEXT_LENGTH}
                      htmlEdited={this.getStaticTextAttribute
                      (data, leadTextAttributes.isHtmlEdited, leadTextDefaults.isHtmlEdited)}
                      html={this.getStaticTextAttribute
                      (data, leadTextAttributes.text, leadTextDefaults.text)}
                      onChange={(html: string, isHtmlEdited: boolean) => {
                        this.updateProperty(leadTextAttributes.text, html)
                        this.updateProperty(leadTextAttributes.isHtmlEdited, isHtmlEdited)
                      }}/>
                  </div>
                </div>
            </div>
          </div>
  }

  protected getTitleText(data: any): string {
    return data.typeId
      ? 'Edit Scheduled Text'
      : 'Add Scheduled Text'
  }

  protected saveDisabled(data: any): boolean {
    const { description, endDate, id, location, startDate } = data
    const isMissingData = isFalsey(description && endDate && location && startDate)
    // no id => create text
    // id => update text
    return !id
    ? isMissingData
    : isMissingData || !this.state.isDirty
  }

  protected xButtonClick(data: any) {
    const currentIsSuperUser = this.state.isSuperUser
    this.setState({
      ...ClearScheduledContentDataState,
      isSuperUser: currentIsSuperUser,
    })
    this.props.closeModal(ScheduledTextModalComponent.key)
  }

  protected updateProperty(property, value) {
    this.props.updateModal(ScheduledTextModalComponent.key, {
      name: property,
      value,
    })
    this.setState({ isDirty: true })
  }

  protected createOrUpdate(payload: any): Promise<any> {
    const selectedLocation = this.getSelectedLocation()
    const customTemplate = isCustomTemplate(this.props.emailLayout ? this.props.emailLayout.email_template : '')
    if (!customTemplate && !this.isScheduleContentEnabled(selectedLocation)) {
      this.setState({
        isSaving: false,
        scheduleErrorMessage: SCHEDULED_CONTENT_NOT_ACTIVE_MESSAGE,
      })
      return Promise.reject(SCHEDULED_CONTENT_NOT_ACTIVE_MESSAGE)
    }
    return this.context.entityMetadata
    .getEntityObject('scheduledContent', payload.communityId, null)
    .then((scheduledContentEntityObject: BaseClientEntity) => {
      const {description, endDate, startDate, styles, typeId} = payload
      scheduledContentEntityObject.setRecordId(payload.communityId, typeId ? typeId : null)
      scheduledContentEntityObject.data.contentType = ScheduledContentTypes.text
      scheduledContentEntityObject.data.endDate = endOfDay(endDate, payload.timezone)
      scheduledContentEntityObject.data.startDate = startOfDay(startDate, payload.timezone)
      scheduledContentEntityObject.data.community_partner_code = payload.community_partner_code
      scheduledContentEntityObject.data.description = description
      scheduledContentEntityObject.data.styles = styles
      scheduledContentEntityObject.data.is_edited = payload.is_edited
      scheduledContentEntityObject.data.location = payload.location ? payload.location.value :
        this.props.locationOptions[0].value
        //only need the community identifier for Create, not Update
      if (!payload.typeId) {
        scheduledContentEntityObject.data.communityIdentifier =  payload.communityId
      }
      return scheduledContentEntityObject.save().then((response) => {
        // Disabling this for now.
        // if (response.is_phishing) {
        //   this.context.store.dispatch(Flash.showFlashError(response.message))
        // }
      })
    })
  }

  private setSegmentCode = (code: any) => {
    this.updateProperty('community_partner_code', code.key)
  }

  private getStaticTextAttribute(data, attribute, defaultValue) {
    return data && data[attribute] ? data[attribute] : defaultValue
  }

  private getStaticTextStylesAttribute(data, attribute, defaultValue) {
    const {styles} = data
    return styles && JSON.parse(styles)[attribute]
      ? JSON.parse(styles)[attribute]
      : defaultValue
  }

  private updateStaticTextStylesAttribute(data, attribute, value) {
    const {styles} = data
    const existingStyles = styles && JSON.parse(styles) ? JSON.parse(styles) : {}
    const updatedStyles = {...existingStyles, [attribute]: value}
    return JSON.stringify(updatedStyles)
  }

  private setSegmentData = (configData: any) => {
    const community_partner_code = this.props.data[this.key].data.community_partner_code
    if (!community_partner_code && configData.length > 0) {
      this.updateProperty('community_partner_code', DEFAULT_COMMUNITY_PARTNER_CODES.ALL)
    }
  }

  private getSelectedLocation = () => {
    return this.props.data[ScheduledTextModalComponent.key].data.location.key
  }
}
