import { isEmpty } from 'lodash'
import React from 'react'
import { formatSendDate } from 'shared_server_client/dates'
import { UpcomingIssue } from 'shared_server_client/types/schedule'

export interface NextNewsletterSendProps {
  className?: string
  timezone?: string,
  nextIssue: UpcomingIssue,
  description?: string,
  showContactCount?: boolean
}
export class NextNewsletterSendComponent extends React.Component<NextNewsletterSendProps> {

  constructor(props: any) {
    super(props)
  }
  public render() {
    return this.hasNextDate() ?
      <div className={this.props.className || 'next-newsletter-wrapper metric-box'}>
        <div className="next-newsletter-wrapper-text">
          {this.props.description ? this.props.description : 'Your next newsletter will send on'}
        </div>
        <div className="next-newsletter-wrapper-date">
          {formatSendDate(this.props.nextIssue.date, (this.props.timezone || this.props.nextIssue.schedule.timezone))}
        </div>
        {this.props.showContactCount && this.props.nextIssue.num_sent &&
        <div className="contact-count">
          To {this.props.nextIssue.num_sent} Contacts
        </div>}
        {this.props.children}
      </div>
    : null
  }

  private hasNextDate(): boolean {
    return this.props.nextIssue && Object.keys(this.props.nextIssue).length > 0 &&
            (!isEmpty(this.props.timezone || (this.props.nextIssue.schedule ?
              this.props.nextIssue.schedule.timezone : null)))
  }

}
