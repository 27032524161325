import { DatePicker } from '@progress/kendo-react-dateinputs'
import { sizedUrl } from 'app/utils'
import classnames from 'classnames'
import { ImageUploadError } from 'components/common/constants'
import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { DropdownComponent } from 'components/dropdown/component'
import { FrameLandscapeIcon } from 'components/icons/frame-landscape'
import { UrlInput } from 'components/input/component'
import { Loading, SIZES } from 'components/loading'
import * as Validation from 'components/validation/validations'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { isFalsey } from 'generic/utility'
import * as React from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { DATEPICKER_DATE_FORMAT, Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { DEFAULT_COMMUNITY_PARTNER_CODES, IMAGE, ScheduledContentTypes } from 'shared_server_client/constants'
import { endOfDay,  startOfDay } from 'shared_server_client/dates'
import { isCustomTemplate, needsImageUpload } from 'shared_server_client/utils'
import {
  ClearScheduledContentDataState,
  IMAGE_MAX_WIDTH,
  ImageDisplayType,
  ImageUploadType,
  InitialScheduledContentModalState,
  INVALID_IMAGE_URL_MESSAGE,
  SCHEDULED_CONTENT_NOT_ACTIVE_MESSAGE,
  SERVER_ERROR_MESSAGE
} from '../constants'
import { ScheduleContentModalBaseComponent, ScheduleContentModalProps } from './base'
import { ModalSize } from '../../../../shared/modals';
import { ImageGalleryModal } from '../../../common/image-gallery/modal';
import { BillingPlanDetailCode } from '../../../../shared_server_client/types/billing_plan';


export class ScheduledImageModalComponent extends ScheduleContentModalBaseComponent {
  public static key: string = 'scheduledImageModal'
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private communityId: string = null
  public fileInputRef;

  constructor(props: ScheduleContentModalProps) {
    super(props, ScheduledImageModalComponent.key)
    this.state = {
      ...InitialScheduledContentModalState,
      showImageUploadButtons: !props.data[ScheduledImageModalComponent.key].data.imageUrl,
    }
    this.fileInputRef = React.createRef()
  }

  public componentDidUpdate(prevProps: Readonly<ScheduleContentModalProps>) {
    if (prevProps.data[ScheduledImageModalComponent.key].data.imageUrl !== this.props.data[ScheduledImageModalComponent.key].data.imageUrl) {
      this.setState({ showImageUploadButtons: !this.props.data[ScheduledImageModalComponent.key].data.imageUrl })
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
    ]).then(([activeCommunity, role]) => {
      this.communityId = activeCommunity.communityId
      const galleryFeature: any = activeCommunity.billingInfo.currentPlan.details.find((x) =>
        x.billing_plan_code === BillingPlanDetailCode.IMAGE_GALLERY)
      this.setState({
        hasImageGalleryAccess: galleryFeature && galleryFeature.limit_val !== 0,
        isSuperUser: role === Roles.super_admin,
      })
    })
  }

  public renderChildren(data) {
    const { endDate, imageUrl, linkUrl, location, startDate, imageTitle, imageAltText } = data

    return this.state.isLoading || this.state.isSaving
    ? <Loading size={SIZES.LARGE}/>
    : <div className="scheduled-image-modal-body">
        <div className="body-container">
          <div className="left-side-container">
            <div className="first-row-container">
              <h5>Image</h5>
              {this.renderFirstRow(data)}
            </div>
            <div className="third-row-container">
              <h5>{'Title'}</h5>
              <div className="url-input-container">
                <Input
                    disabled={this.shouldNameDisabled(data)}
                    type="text"
                    value={imageTitle ? imageTitle : ''}
                    onChange={(e) => this.updateProperty('imageTitle', e.target.value)}/>
              </div>
            </div>
            <div className="third-row-container">
              <h5>{'Alt Text'}</h5>
              <div className="url-input-container">
                <Input
                    type="text"
                    value={imageAltText}
                    onChange={(e) => this.updateProperty('imageAltText', e.target.value)}/>
              </div>
            </div>
            <div className="second-row-container">
              <h5>Location</h5>
              <DropdownComponent
                  data={this.props.locationOptions}
                  selected={location ? location.key : ''}
                  onChange={this.changeLocation}/>
            </div>
            <div className="third-row-container">
              <h5>{'Link Url (call to action)'}</h5>
              <div className="url-input-container">
                <Input
                    type="text"
                    value={linkUrl ? linkUrl : ''}
                    onChange={(e) => this.updateProperty('linkUrl', e.target.value)}/>
              </div>
            </div>
            <div className="fourth-row-container">
              <div className="start-date-container">
                <h5>Start Date</h5>
                <DatePicker
                    className="modal-schedule-date-picker"
                    format={DATEPICKER_DATE_FORMAT}
                    value={startDate}
                    onChange={(e) => this.updateProperty('startDate', e.target.value)}
                />
              </div>
              <div className="end-date-container">
                <h5>End Date</h5>
                <DatePicker
                    className="modal-schedule-date-picker"
                    format={DATEPICKER_DATE_FORMAT}
                    value={endDate}
                    onChange={(e) => this.updateProperty('endDate', e.target.value)}
                />
              </div>
            </div>
            <div className="fifth-row-container">
              <div className="segment-code-container">
                <LoadSegmentCodes
                    label="Segment"
                    setSegmentData={this.setSegmentData}
                    segmentCode={data.community_partner_code}
                    setSegmentCode={this.setSegmentCode}/>
              </div>
            </div>
            {
                this.state.modalErrorMessage &&
                <div className="error-container">
                  {this.state.modalErrorMessage}
                </div>
            }
            {
                this.state.scheduleErrorMessage &&
                <div className="error-container">
                  {this.state.scheduleErrorMessage}
                  <a onClick={() => this.enableScheduleContent(this.getSelectedLocation(), this.communityId)} href="#">Click
                    here to toggle it on.</a>
                </div>
            }
          </div>
          <div className="right-side-container">
            <h5>Preview</h5>
            <div className="preview-container">
              {
                imageUrl
                  ? <img src={sizedUrl(imageUrl, 250, 400)} onError={this.onImageError}/>
                  : <FrameLandscapeIcon svgWidth={400} svgHeight={250}/>
              }
              { (imageUrl) &&
                 this.getUploadErrorMsg(data)
              }
            </div>
          </div>
        </div>
        {(this.state.hasImageGalleryAccess || this.state.isSuperUser) &&
          <ImageGalleryModal
            data={this.props.data}
            onSelectImageFromGallery={(selectedImage: {url: string, imageWidth: number, imageHeight: number}) => {
              this.setState({
                sourceUrl: selectedImage.url,
                validUrl: Validation.validateUrl(selectedImage.url).valid,
                useUrl: true
              })
              this.updateProperty('imageUrl', selectedImage.url)
              this.updateProperty('imageWidth', selectedImage.imageWidth)
              this.updateProperty('imageHeight', selectedImage.imageHeight)
            }}
            closeModal={this.props.closeModal}
            closeButtonText={'Cancel'}
            size={ModalSize.Large}/>
        }
      </div>
  }

  protected changeLocation = (e) => {
    this.updateProperty('location', e.selected)
    this.updateProperty('imageAltText', this.generateAltText(e.selected.key))
  }

  protected getTitleText(data: any): string {
    return data.typeId
      ? 'Edit Scheduled Image'
      : (data.isCloneItem) ? 'Clone Scheduled Image' : 'Add Scheduled Image'
  }

  protected saveDisabled(data: any): boolean {
    const { endDate, id, imageUrl, location, startDate, imageAltText } = data
    const isMissingData = isFalsey(endDate && imageUrl && location && startDate && imageAltText !== '')
    // no id => create image
    // id => update image
    return !id
    ? isMissingData || !Validation.validateUrl(imageUrl).valid
    : isMissingData || !this.state.isDirty
  }

  protected xButtonClick(data: any) {
    const currentIsSuperUser = this.state.isSuperUser
    this.setState({
      ...ClearScheduledContentDataState,
      isSuperUser: currentIsSuperUser,
    })
    this.props.closeModal(ScheduledImageModalComponent.key)
  }

  protected createOrUpdate(payload: any): Promise<any> {
    const selectedLocation = this.getSelectedLocation()
    const customTemplate = isCustomTemplate(this.props.emailLayout ? this.props.emailLayout.email_template : '')
    if (!customTemplate && !this.isScheduleContentEnabled(selectedLocation)) {
      this.setState({
        isSaving: false,
        scheduleErrorMessage: SCHEDULED_CONTENT_NOT_ACTIVE_MESSAGE,
      })
      return Promise.reject(SCHEDULED_CONTENT_NOT_ACTIVE_MESSAGE)
    }
    return this.context.entityMetadata
    .getEntityObject('scheduledContent', payload.communityId, null)
    .then((scheduledContentEntityObject: BaseClientEntity) => {
      const {endDate, imageDisplayType, imageTitle, imageAltText, imageUrl, imageHeight, imageWidth,
        linkUrl, startDate, typeId} = payload
      scheduledContentEntityObject.setRecordId(payload.communityId, typeId && !payload.isCloneItem ? typeId : null)
      scheduledContentEntityObject.data.contentType = ScheduledContentTypes.image
      scheduledContentEntityObject.data.endDate = endOfDay(endDate, payload.timezone)
      scheduledContentEntityObject.data.startDate = startOfDay(startDate, payload.timezone)
      scheduledContentEntityObject.data.community_partner_code = payload.community_partner_code
      scheduledContentEntityObject.data.imageUrl = imageUrl
      scheduledContentEntityObject.data.imageHeight = imageHeight
      scheduledContentEntityObject.data.imageWidth = imageWidth
      if (this.state.useUrl) {
        scheduledContentEntityObject.data.imageDisplayType = imageDisplayType
      } else {
        scheduledContentEntityObject.data.imageDisplayType = ImageDisplayType.FIXED
      }
      scheduledContentEntityObject.data.linkUrl = linkUrl
      scheduledContentEntityObject.data.isClientUrl = needsImageUpload(imageUrl)
      scheduledContentEntityObject.data.location = selectedLocation
      scheduledContentEntityObject.data.title = imageTitle
      scheduledContentEntityObject.data.image_alt_text = imageAltText
      //only need the community identifier for Create, not Update
      if (!payload.typeId) {
        scheduledContentEntityObject.data.communityIdentifier =  payload.communityId
      }
      return scheduledContentEntityObject.save()
    })
  }

  protected updateProperty(property, value) {
    this.props.updateModal(ScheduledImageModalComponent.key, {
      name: property,
      value,
    })
    this.setState({ isDirty: true })
  }

  protected updateSourceUrl = (e) => {
    this.validateUrl(e.target.value)
    this.setState({ sourceUrl: e.target.value })
  }

  protected validateUrl = (url: string) => {
    this.setState({
      errorMessage: '',
      validUrl: this.state.useUrl ? Validation.validateUrl(url).valid : Validation.validateImageUrl(url).valid,
    })
  }

  protected fetch = (inputUrl: string): Promise<any> => {
    const imageValidateEndpoint: string = AjaxWrapper.getServerUrl() + '/image/validate-url'
    return AjaxWrapper.ajax(imageValidateEndpoint, HttpMethod.POST, {url: inputUrl})
  }

  protected validateFetch = (inputUrl: string) => {
    this.setState({isUploading: true})
    return this.fetch(inputUrl)
    .then((result: any) => {
      if (result) {
        this.setState({ isUploading: false })
        this.updateProperty('imageUrl', inputUrl)
        this.updateProperty('imageDisplayType', this.state.imageDisplayType)
      } else {
        this.setState({
          errorMessage: INVALID_IMAGE_URL_MESSAGE,
          isUploading: false,
          validUrl: false,
        })
      }
    })
    .catch((err) => {
        this.setState({
          errorMessage: SERVER_ERROR_MESSAGE,
          isUploading: false,
          validUrl: false,
        })
    })
  }

  private getUploadErrorMsg(data: any) {
    let _isInValidImage: boolean = false
    if (data.imageWidth) {
      if (data.location.key && data.location.key.indexOf(ImageUploadType.BANNER) > -1) {
          _isInValidImage = data.imageWidth > IMAGE_MAX_WIDTH.BANNER
      }
      if (data.location.key && data.location.key.indexOf(ImageUploadType.SQUARE) > -1) {
        _isInValidImage = data.imageWidth > IMAGE_MAX_WIDTH.SQUARE
      }
    }
    if (_isInValidImage) {
      return  <div className= "Image-Upload-Size-ErrorMsg Image-Upload-Size-ErrorMsg-Label">
                {ImageUploadError.LargeImage}
              </div>
    }
  }

  private setSegmentCode = (code: any) => {
    this.updateProperty('community_partner_code', code.key)
  }

  private renderFirstRow = (data) => {
    return <div className={classnames('image-loader-and-buttons-container', this.state.errorMessage ? 'add-3rem-margin-bottom' : '')}>
              {this.renderInputBox(data)}
              <div className="buttons-container mr-0">
                {this.renderFirstRowNew(data)}
              </div>
          </div>
  }

  private renderUploadAndCancel = (data) => {
    return <div>
      <Button className="generate" disabled={!this.state.validUrl}
        onClick={() => this.validateFetch(this.state.sourceUrl)}>
        <span>Upload</span>
      </Button>
      <Button className="generate"
        onClick={this.cancelUpload}>
        <span>Cancel</span>
      </Button>
    </div>
  }

  private renderBrowseAndUseUrl = (data) => {
    return this.state.showImageUploadButtons ? <div>
      <div className="browse-delete d-flex mb-0">
        <Button className="generate"
          onClick={() => this.fileInputRef.current.click()}>
          <span>Upload Image</span>
        </Button>
        <input type="file"
                className="hidden"
                ref={this.fileInputRef}
                onChange={(e) => this.uploadImage(e.target.files[0])}
                id="image"
                name="image"
                accept="image/png, image/jpeg, image/gif">
        </input>
        <Button className="generate"
          onClick={this.useUrl}>
          <span>Input Image URL</span>
        </Button>
        {(this.state.hasImageGalleryAccess || this.state.isSuperUser) &&
          <Button className="generate"
            onClick={this.openImageGalleryModal}>
            <span>Image Library</span>
          </Button>
        }
      </div>
    </div> : <div className="browse-delete d-flex mb-0">
          <Button className="generate"
            onClick={() => this.onChangeClick()}>
            <span>Change</span>
          </Button>
        </div>
  }

  private renderFirstRowNew = (data) => {
    if (!this.state.useUrl) {
      return this.renderBrowseAndUseUrl(data)
    } else {
      return this.renderUploadAndCancel(data)
    }
  }

  private renderInputBox = (data) => {
    if (this.state.useUrl) {
      return <div>
              <Row form>
                <Col md={12}>
                  <UrlInput
                    errorMessage={this.state.errorMessage}
                    onChange={this.updateSourceUrl}
                    onKeyPress={this.handleKeyPress}
                    placeholder={'Please input your image url here'}
                    valid={this.state.validUrl}
                    value={this.state.sourceUrl || data.imageUrl}/>
                </Col>
              </Row>
              <Row form>
                <Col md={4}>
                  <FormGroup check>
                    <Label check>
                      <Input onChange={this.onImageDisplayTypeChange}
                        value={ImageDisplayType.DYNAMIC}
                        checked={this.state.imageDisplayType === ImageDisplayType.DYNAMIC}
                        type="radio" name="imageDisplayType" />
                      {ImageDisplayType.DYNAMIC}
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup check>
                    <Label check>
                      <Input onChange={this.onImageDisplayTypeChange}
                        value={ImageDisplayType.FIXED}
                        checked={this.state.imageDisplayType === ImageDisplayType.FIXED}
                        type="radio" name="imageDisplayType" />
                      {ImageDisplayType.FIXED}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
    } else {
      return null
    }
  }

  private onImageDisplayTypeChange = (event: any) => {
    this.updateProperty('imageDisplayType', event.target.value)
    this.setState({
      imageDisplayType: event.target.value,
    })
  }

  private uploadImage = (image: any) => {
    this.setState({
      isUploading: true,
      useUrl: false,
    })
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage: any) => {
        this.setState({
          isUploading: false,
        })
        this.updateProperty('imageUrl', hostedImage.url)
        this.updateProperty('imageWidth', hostedImage.width)
        this.updateProperty('imageHeight', hostedImage.height)
      })
      .catch((error) => {
        this.setState({
          isUploading: false,
        })
      })
  }

  private cancelUpload = () => {
    this.setState({imageName: '', imageUrl: '', useUrl: false, errorMessage: '',
                    modalErrorMessage: '', scheduleErrorMessage: '', sourceUrl: '', validUrl: false})
    this.updateProperty('imageUrl', '')
    this.updateProperty('imageHeight', '')
    this.updateProperty('imageWidth', '')
    this.updateProperty('imageTitle', '')
    this.updateProperty('image_alt_text', '')
  }

  private onChangeClick = () => {
    this.setState({isEditingImage: true})
    this.updateProperty('imageUrl', '')
    this.updateProperty('imagHeight', '')
    this.updateProperty('imageWidth', '')
  }

  private useUrl = () => {
    this.setState({useUrl: true})
  }

  private openImageGalleryModal = (e) => {
    e.preventDefault()
    this.props.openModal(ImageGalleryModal.key, {})
  }

  private onImageError = () => {
    this.updateProperty('imageUrl', '');
    this.setState({
      validUrl: false,
      sourceUrl: '',
    })
  }

  private shouldNameDisabled = (data: any): boolean => {
    return (data.isCloneItem) ? false : (data.id && new Date(data.startDate) < new Date())
  }

  private setSegmentData = (configData: any) => {
    const community_partner_code = this.props.data[this.key].data.community_partner_code
    if (!community_partner_code && configData.length > 0) {
      this.updateProperty('community_partner_code', DEFAULT_COMMUNITY_PARTNER_CODES.ALL)
    }
  }

  private getSelectedLocation = () => {
    return this.props.data[ScheduledImageModalComponent.key].data.location.key
  }

  private generateAltText = (currentLocation = '') => {
    if(!this.props.data[ScheduledImageModalComponent.key].data.location){
      return ''
    }
    if(!currentLocation){
      currentLocation = this.getSelectedLocation()
    }
    const altTextKey = currentLocation.split(' ')[0].toLowerCase() + '_alt_text'
    return this.props.emailLayout[altTextKey]
  }
}
