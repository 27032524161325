import { ScheduleFrequency } from 'shared_server_client/constants'
import { UpcomingIssue } from 'shared_server_client/types/schedule'

export enum DayOfWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export enum WeekOfMonth {
  FirstWeek = '1st',
  SecoundWeek = '2nd',
  ThirdWeek = '3rd',
  FourthWeek = '4th',
  FifthWeek = '5th',
}

export class WeekInfo {
  public week: WeekOfMonth
  public selected: boolean
  public recommended: boolean
}

export class DayInfo {
  public day: DayOfWeek
  public selected: boolean
  public recommended: boolean
}

export interface ScheduleSettings {
  cutoffOffset: number,
  frequency: ScheduleFrequency,
  isActive: number,
  nextIssue?: UpcomingIssue,
  selectedDays: DayInfo[],
  selectedWeeks: WeekInfo[],
  startDate: Date,
  time: string,
  timezone: string,
}

export const emptyScheduleSettings: ScheduleSettings = {
  cutoffOffset: 60,
  frequency: ScheduleFrequency.none,
  isActive: 1,
  selectedDays: [],
  selectedWeeks: [],
  startDate: null,
  time: '',
  timezone: '',
}
