import { InitialScheduledContentModalState } from '../constants'
import { ScheduleContentModalProps } from './base'
import { ScheduledArticleBaseComponent } from './scheduleArticleBaseComponent'

export class SponsoredArticleModalComponent extends ScheduledArticleBaseComponent {
  public static key: string = 'sponsoredArticleModal'

  constructor(props: ScheduleContentModalProps) {
    super(props, SponsoredArticleModalComponent.key)
    this.state = InitialScheduledContentModalState
  }
}
