import { addHours, format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { HolidaySchedule, UpcomingIssue } from 'shared_server_client/types/schedule'

const HOLIDAY_TYPE = 'Public holiday'

export const mapIssuesToSchedules = (issues: UpcomingIssue[], hasScheduledContentAccess: boolean): any[] => {
  return issues.map((issue, i) => {
    const schedule = issue.schedule
    const onMessage = !hasScheduledContentAccess ? '' : issue.hasScheduledContent ? 'Scheduled Content' : ''
    const updatedIssue = {...issue, hasScheduledContent: onMessage === 'Scheduled Content'}
    return {
      id: i.toString(),
      body: {updatedIssue},
      borderColor: getBorderColor(issue, onMessage),
      calendarId: '0',
      title: !schedule.is_active ? 'Schedule is paused' : issue.is_active ? onMessage : '',
      category: 'time',
      dueDateClass: '',
      start: issue.issue_date.toISOString(),
      end: addHours(issue.issue_date, 1).toISOString(),
      isvisible: true,
    }
  })
}

export const mapSchedules = (issues: UpcomingIssue[], holidays: any[], hasScheduledContentAccess: boolean): any[] => {
  const currentHolidays = mapHolidaysToSchedules(holidays)
  let schedules = mapIssuesToSchedules(issues, hasScheduledContentAccess)
  schedules = [...currentHolidays, ...schedules]
  schedules = schedules.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
  return schedules
}

export const mapHolidaysToSchedules = (currentHolidays: HolidaySchedule[]): any[] => {
  currentHolidays = currentHolidays.filter((holiday) => holiday.description === HOLIDAY_TYPE)
  return currentHolidays.map((holiday, i) => {
    return {
      id: i.toString(),
      bgColor: 'white',
      borderColor: 'white',
      calendarId: '0',
      title: holiday.title,
      category: 'allday',
      isvisible: true,
      dueDateClass: '',
      start: holiday.startDate,
      end: holiday.endDate,
    }
  })
}

export const getMonthYear = (date: Date): string => {
  return format(date, 'MMMM yyyy')
}

export const normalizeIssues = (issues: UpcomingIssue[], timezone: string): UpcomingIssue[] => {
  return issues.map((i: UpcomingIssue) => ({
    ...i,
    id: i.holiday_id,
    issue_date: utcToZonedTime(i.date, timezone),
  }))
}

export const normalizeHolidays = (holidays: HolidaySchedule[], timezone: string): HolidaySchedule[] => {
  return holidays.map((h: HolidaySchedule) => ({
    ...h,
    startDate: isoToDateString(h.startDate),
    endDate: isoToDateString(h.endDate),
  }))
}

const getBorderColor = (issue, onMessage) => {
  const schedule = issue.schedule
  return (!schedule.is_active || !issue.is_active) ?
  'red'
  : '#72cac2'
}

export const isoToDateString = (isoDate: string): string => {
  return isoDate.substring(0, 10)
}
