import React, {Component} from 'react'
import './styles.css'

import { WeekInfo} from './data'
import {WeeklyDay} from './weeklyDay'
import * as weekOccurence from './weekOccurence'

interface MultiWeekSelectorProps {
  onChange: any,
  selectedWeeks: WeekInfo[]
}

interface MultiWeekSelectorState {
  weekData: WeekInfo[],
}
export class MultiWeekSelector extends Component<MultiWeekSelectorProps, MultiWeekSelectorState> {
    constructor(props: MultiWeekSelectorProps) {
      super(props)
      this.state = { weekData: weekOccurence.createMonthlyWeekData(props.selectedWeeks || []) }
    }

    public render() {
      return <div className="">
        <div className="schedule-detail-text">Choose which occurrence of the month</div>
        <div className="schedule-frequency-daily-group">
          {this.createWeeks()}
        </div>
      </div>
  }

  private handleWeekChange(selectedWeek: WeekInfo) {
    const newWeekData: WeekInfo[] = this.state.weekData.map((w: WeekInfo) => {
      return w.week === selectedWeek.week ? {...w, selected: selectedWeek.selected} : {...w, selected: false}
    })

    // update our internal state
    this.setState((state) => {
      return {
        ...state,
        weekData: newWeekData,
      }
    })

    if (this.props.onChange) {
      this.props.onChange(newWeekData)
    }
  }

  private createWeek(week: WeekInfo) {
    return <WeeklyDay key={week.week} weekInfo={week} onChange={(e: WeekInfo) => this.handleWeekChange(e)}/>
  }

  private createWeeks() {
    return this.state.weekData.map((item) => this.createWeek(item))
  }
}
