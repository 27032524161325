import capitalize from 'lodash/capitalize'
import React, {Component} from 'react'
import { ScheduleFrequency } from 'shared_server_client/constants'
import './styles.css'

interface FrequencySelectorProps {
  frequency: ScheduleFrequency,
  onChange: any,
}

interface FrequencySelectorState {
  frequency: ScheduleFrequency,
}

export class FrequencySelector extends Component<FrequencySelectorProps, FrequencySelectorState> {
    constructor(props: FrequencySelectorProps) {
      super(props)
      this.state = {
        frequency: props.frequency,
      }
    }
    public render() {
      return <div>
          <div className="schedule-header-text">Frequency</div>
          <div className="schedule-detail-text">How often would you like to send your newsletter?</div>
          <div className="schedule-frequency-weekly-group">
            {this.doButton(ScheduleFrequency.weekly)}
            {this.doButton(ScheduleFrequency.biweekly)}
            {this.doButton(ScheduleFrequency.monthly)}
            {this.doButton(ScheduleFrequency.manually)}
          </div>
        </div>
    }

    public componentDidUpdate(prevProps: FrequencySelectorProps, prevState: FrequencySelectorState) {
      if (this.props.frequency !== prevProps.frequency) {
        this.setState(this.props)
      }
    }

    private handleFrequencyChange(newFrequency: ScheduleFrequency) {
      if (this.state.frequency !== newFrequency) {
        // update our internal state as it's going from weekly to bi-weekly or vice versa
        this.setState((state) => {
          return {
            ...state,
            frequency: newFrequency,
          }
        })
        if (this.props.onChange) {
          this.props.onChange(newFrequency)
        }
      }
    }

    private doButton(frequency: ScheduleFrequency) {
      if (this.state.frequency === frequency) {
        return <div className="schedule-frequency-weekly-item schedule-frequency-item-selected  clickable-item"
          onClick={() => this.handleFrequencyChange(frequency)}>
          <span>{capitalize(frequency)}</span>
          <div className="schedule-item-checkbox schedule-weekly-checkbox clickable-item" />
        </div>
      } else {
        return <div className="schedule-frequency-weekly-item clickable-item"
                    onClick={() => this.handleFrequencyChange(frequency)}>
        <span>{capitalize(frequency)}</span>
        </div>
      }
    }
}
