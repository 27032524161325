import { DatePicker } from '@progress/kendo-react-dateinputs'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Tooltip } from '@progress/kendo-react-tooltip'
import { RasaTimePicker } from 'components/time-picker/component'
import { RasaTimeZone } from 'components/time-zone/component'
import { registerNewComponentWithModals } from 'generic/genericRedux'
import React from 'react'
import { Input } from 'reactstrap'
import { DEFAULT_TIMEZONE, ScheduleFrequency } from 'shared_server_client/constants'
import { MAX_CUTOFF_OFFSET, MIN_CUTOFF_OFFSET } from '../constants'
import { emptyScheduleSettings, ScheduleSettings as ScheduleSettingsData } from '../data'
import { FrequencySelector } from '../frequencySelector'
import { ManualSendModal } from '../modals'
import { MultiDaySelector } from '../multidaySelector'
import { ScheduleSettingsComponent, ScheduleSettingsProps } from '../scheduleSettings'
import './_styles.scss'

class MobileScheduleSettingsComponent extends ScheduleSettingsComponent {
  constructor(props: ScheduleSettingsProps) {
    super(props)
  }

  public renderEditor() {
  return <div className="mobile-schedule-wrapper">
      <div className="mobile-section-header-text">{'Day & Time'}</div>
        <div className="mobile-schedule-delivery-sub-group mobile-schedule-new-group">
        <div className="mobile-schedule-header-text">Pause Schedule</div>
        <div className="mobile-schedule-detail-text">Pause your newsletter after it's been activated.</div>
          <DropDownList data={this.props.data.isActive ? [{text: 'Paused', value: 0}] :
                          [{text: 'Active', value: 1}]}
                        textField="text"
                        defaultItem={this.props.data.isActive ?
                                    {text: 'Active', value: 1} :
                                    {text: 'Paused', value: 0}}
                        onChange={(e) => this.propertyChanged('isActive', e.target.value.value)}/>
        <div className="mobile-schedule-delivery-sub-group mobile-schedule-new-group">
          <div className="mobile-schedule-header-text ">Delivery Time</div>
          <div className="mobile-schedule-detail-text">Select your delivery time.</div>
            <div className="delivery-time-wrapper">
              <div className="time-picker">
                <RasaTimePicker
                  onChange={(e: any) => this.handleDeliveryTimeChange(e)}
                  time={this.props.data.time}
                  timeZone={null}>
                </RasaTimePicker>
              </div>
              <div className="time-zone">
                <RasaTimeZone
                  timeZone={this.props.data.timezone || DEFAULT_TIMEZONE}
                  onChange={this.timeZoneChanged}
                />
              </div>
            </div>
        </div>
      </div>
      <div className="mobile-schedule-delivery-sub-group mobile-schedule-new-group">
        <FrequencySelector onChange={(e: any) => this.updateFrequency(e)}
                          frequency={this.props.data.frequency}>
        </FrequencySelector>
      </div>
      {this.props.data.frequency !== ScheduleFrequency.manually
        ?
      <div className="standard-settings-container">
        <div>
          <MultiDaySelector selectedDays={this.props.data.selectedDays}
                            onChange={(e: any) => this.propertyChanged('selectedDays', e)}>
          </MultiDaySelector>
        </div>
        <div className="clearfix">
          <div className="mobile-schedule-delivery-sub-group mobile-schedule-new-group">
            <Tooltip openDelay={100} position="top" anchorElement="pointer" className="mobile-rasa-grid-tooltip">
              <div className="mobile-schedule-header-text ">
                <span title={this.cutoffTimeTooltip()}>
                  Content Review Cut-Off Time
                </span>
              </div>
            </Tooltip>
            <div className="mobile-schedule-detail-text">
              We will stop pulling new content into your article pool this many hours ahead of time.
            </div>
            <div className="cutoff-offset-container">
              <Input
                value={this.props.data.cutoffOffset}
                max={MAX_CUTOFF_OFFSET}
                min={MIN_CUTOFF_OFFSET}
                step={1}
                format={'n0'}
                onChange={this.updateOffset}/>
            </div>
          </div>
        </div>
        <div className="mobile-schedule-start-date-container mobile-schedule-new-group">
          <div className="mobile-schedule-header-text">Select Start Date</div>
          <div className="mobile-schedule-detail-text">
            Your first newsletter will be sent on or after this day.
          </div>
          <div className="mobile-footer">
            <DatePicker className="mobile-schedule-date-picker"
                        format="yyyy-MM-dd"
                        value={this.props.data.startDate}
                        onChange={(e) => this.propertyChanged('startDate', this.midnight(e.value))} ></DatePicker>
          </div>
          </div>
      </div>
      :
      <div className="clearfix">
        {this.getManualScheduleSettingsJSX()}
      </div>}
      <div className={`done-button ${this.isValid() ? 'clickable-item done-button-enabled' : 'done-button-disabled rasa-noclick'}`}
            onClick={() => this.saveSchedule()}>
        {this.state.savedSchedule ?
          <p className="saving-schedule">
            Schedule Changed!
          </p>
          :
          <p>
            Save Schedule
          </p>
        }
        </div>
    </div>
  }
}

export const MobileScheduleSettings = registerNewComponentWithModals<ScheduleSettingsData>(
  MobileScheduleSettingsComponent,
  'mobile.schedule',
  [ManualSendModal.key],
  emptyScheduleSettings)
